.terms {
    background: transparent url(../../assets/bg-team.png) center 0 repeat;
    font-family: 'regencielight-webfont', sans-serif;
}
.terms h1 {
    font-size: 30px;
    color: #000000;
    padding-top: 40px;
    /* padding: 0; */
    line-height: 1.2;
    font-family: 'regencielight-webfont', sans-serif;
    text-align: center;
}
.terms p.subtitle {
    font-size: 13px;
    font-family: 'regencielight-webfont', sans-serif;
    /* margin: 20px auto 40px auto; */
    text-align: center;
}
.terms h2 {
    font-size: 26px;
    color: #000000;
    padding-top: 10px;
    /* padding: 0; */
    line-height: 1.2;
    font-family: 'regencielight-webfont', sans-serif;
    text-align: center;
}
.terms h3 {
    font-size: 20px;
    color: #000000;
    padding-top: 5px;
    /* padding: 0; */
    line-height: 1.2;
    font-family: 'regencielight-webfont', sans-serif;
}

