body {
  font-family: "museo500", Helvetica, Arial, sans-serif;
  margin: 0;
  font-size: 75%;
  line-height: 1.4;
  color: #333333;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
