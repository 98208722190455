#navbarMLG {
    font-family: "museo500", Helvetica, Arial, sans-serif;
    color: #050505;
    font-size: 1.1em; 
    margin: 0 0 0 42px;
    padding: 0 10px 0 0;  
}
.main > li > a {
    color: #050505;
    font-size: 1.4em;
}
.navbar {
    padding-bottom: 0;
}

@media screen and (max-width:980px) {  
    .navbar {
        width: 100%;
    }       
}
@media screen and (max-width:768px) {  
    .navbar {
        width: 100%;
    }       
}
@media screen and (max-width:576px) {  
    .navbar {
        width: 100%;
    }       
}
